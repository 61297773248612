.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about h3 {
    color: #D32F2F;
    font-size: 2.2em;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    border-bottom: 2px solid #D32F2F;
    padding-bottom: 10px;
}

.about p {
    color: #555; 
    font-size: 1.2em;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
    padding-left: 15px;
}

.about ul {
    list-style-type: square;
    padding-left: 30px;
    color: #333;
}

.about li {
    margin-bottom: 10px;
}
.about img {
    display: block; /* Hace que la imagen se comporte como un bloque */
    margin: 100px auto; /* Centra la imagen horizontalmente y añade un margen superior e inferior de 20px */
    max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
}
/* Estilo para el encabezado h2 */
.contact-page h2 {
    color: #B22222; /* Rojo oscuro, acorde a los colores corporativos */
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

/* Estilo para los párrafos p */
.contact-page p {
    color: #333; /* Color plomo oscuro para un texto formal */
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 15px;
    text-align: center;
}

/* Estilo adicional para la sección de WhatsApp */
.contact-page .whatsapp-contact {
    margin-top: 20px;
    text-align: center;
}

.contact-page .whatsapp-contact p {
    color: #555; /* Un tono plomo más claro */
}

.contact-page .whatsapp-numbers {
    display: flex; /* Usa flexbox para alinear los elementos horizontalmente */
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Alinea los elementos verticalmente al centro */
    gap: 20px; /* Añade un espacio entre los elementos */
}

.contact-page .whatsapp-numbers span {
    display: flex;
    justify-content: center; 
}

.contact-page .whatsapp-icon {
    color: #25D366; /* Verde característico de WhatsApp */
    margin-right: 10px;
    font-size: 2em;
}

.contact-page a {
    text-decoration: none;
    color: #B22222;
    font-weight: bold;
}

.contact-page a:hover {
    color: #FF4500; /* Un rojo más vibrante para el hover */
}


/* Services.css */
.services-container {
    max-width: 800px; /* Limita el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    padding: 20px; /* Añade un espacio interno de 20 píxeles alrededor del contenido */
    background-color: #f9f9f9; /* Establece el color de fondo del contenedor */
    border-radius: 10px; /* Redondea las esquinas del borde del contenedor */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Añade una sombra al contenedor */
}

.services-text {
    text-align: left; /* Centra el texto */
}

.services-text h1 {
    color: #B22222; /* Color del título principal */
}

.services-text h2 {
    color: #D32F2F; /* Color de los subtítulos */
}

.services-text p {
    color: #555; /* Color del párrafo */
    font-size: 1.2em; /* Tamaño de la fuente */
    line-height: 1.8; /* Espaciado entre líneas */
}

.service {
    display: flex; /* Usa flexbox para alinear los elementos */
    align-items: flex-start; /* Alinea los elementos verticalmente al inicio */
    margin-bottom: 20px; /* Añade un margen inferior de 20 píxeles */
}

.service-image {
    width: 190px; /* Ajusta el tamaño según lo necesites */
    height: 260px; /* Ajusta el tamaño según lo necesites */
    margin-right: 20px; /* Añade un margen derecho de 20 píxeles */
    /*transform: rotate(-10deg); /* Gira la imagen */
    border-radius: 20%; /* Hace la imagen circular */
    object-fit: cover; /* Asegura que la imagen se ajuste dentro del círculo */
    transition: transform 0.3s ease; /* Añade una transición suave */
}
/*
.service-image:hover {
    transform: rotate(-10deg) scale(1.2); /* Aplica el zoom al pasar el cursor */
/*}
*/
.service-description {
    max-width: 500px; /* Limita el ancho máximo de la descripción */
}
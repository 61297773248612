/* Contenedor principal */
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilos para las secciones */
section {
  margin: 20px 0;
  padding: 20px;
  background-color: #fafafa; /* Fondo plomo claro */
  border-radius: 8px;
}

section h2 {
  margin-top: 0;
  color: #b71c1c; /* Rojo */
}

/* Estilos para el formulario de contacto */
form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  margin: 0 auto;
}

form div {
  margin-bottom: 15px;
  width: 100%;
}

form label {
  margin-bottom: 5px;
  font-weight: bold;
}

form input,
form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #b71c1c; /* Rojo */
  color: white;
  font-size: 1em;
  cursor: pointer;
}

form button:hover {
  background-color: #880e4f; /* Rojo más oscuro */
}

/* Estilos para los enlaces de navegación con íconos */
nav ul li a {
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
}

nav ul li a .icon {
  margin-right: 8px;
  display: inline-block;
}

.logo {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Bordes redondeados */
}
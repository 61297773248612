/* src/components/Home.css */
.home-container {
    position: relative;
    background-image: url('../assets/images/mosca2.jpg'); 
    background-size: cover;
    background-position: center;
    height: 100vh; /* Ajustar la altura */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .intro-text {
    position: relative;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    padding: 20px;
    border-radius: 10px;
  }
  
  .intro-text h1 {
    font-size: 3em; /* Ajustar el tamaño de fuente */
    margin-bottom: 10px;
  }
  
  .intro-text p {
    font-size: 1.5em; /* Ajustar el tamaño de fuente */
  }
  
  .home-footer {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    text-align: center;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.home-footer a {
    color: #4CAF50;
    text-decoration: none;
}

.home-footer a:hover {
    text-decoration: underline;
}
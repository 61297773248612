/* Estilos globales */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4; /* Fondo plomo claro */
  color: #333; /* Texto gris oscuro */
}

/* Estilos para el encabezado */
header {
  background-color: #333; /* Negro */
  color: white;
  padding: 20px 0;
  text-align: center;
}

header h1 {
  margin: 0;
  font-size: 2.5em;
}

/* Estilos para la barra de navegación */
nav ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
  background-color: #444; /* Gris oscuro */
  margin: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  padding: 10px 20px;
  display: inline-block;
}

nav ul li a:hover {
  background-color: #b71c1c; /* Rojo oscuro */
  border-radius: 4px;
}

/* Estilos para el pie de página */
footer {
  background-color: #333; /* Negro */
  color: white;
  text-align: center;
  padding: 20px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}
